import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './vela_ft.svg';
import AuthService from '../services/auth.services';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SigninForm from './SigninForm';

function ForgotPassword() {
  const [step, setStep] = useState(1); // 1: Enter email, 2: Enter OTP, 3: Set new password
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (email) {
      try {
        const response = await AuthService.resetPassword(email);
        if (response.status === 200 || response.status === 201) {
          toast.success('Password reset OTP has been sent to your email', {
            style: { backgroundColor: '#212332', color: 'white' },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
          setEmailSent(true);
          setStep(2);
        } else {
          toast.error('Unexpected response from server', {
            style: { backgroundColor: '#212332', color: 'white' },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        toast.error('Error sending OTP', {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      toast.error('Please enter your email', {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (otp && newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        toast.error('Passwords do not match', {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      try {
        const response = await AuthService.changePassword(
          email,
          newPassword,
          otp
        );
        if (response.status === 200 || response.status === 201) {
          toast.success('Password has been reset successfully', {
            style: { backgroundColor: '#212332', color: 'white' },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        } else {
          toast.error('Invalid OTP or email', {
            style: { backgroundColor: '#212332', color: 'white' },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        // Check if the error response has validation messages
        if (
          error.response &&
          error.response.data &&
          Array.isArray(error.response.data.message)
        ) {
          const validationMessages = error.response.data.message;
          if (validationMessages.length > 0) {
            toast.error(validationMessages[0], {
              style: { backgroundColor: '#212332', color: 'white' },
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            });
          } else {
            toast.error('Error resetting password', {
              style: { backgroundColor: '#212332', color: 'white' },
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            });
          }
        } else {
          toast.error('Error resetting password', {
            style: { backgroundColor: '#212332', color: 'white' },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    } else {
      toast.error('Please enter OTP, new password, and confirm password', {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <>
      <SigninForm>
        <ToastContainer
          position="top-center"
          draggable={false}
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          transition={Zoom}
          closeButton={false}
        />
        <div>
          <div
            style={{
              position: 'fixed',
              zIndex: '9999',
              inset: '16px',
              pointerEvents: 'none',
            }}
          ></div>
          <div>
            <div
              className="flex rounded-lg justify-center max-w-2xl mx-auto my-auto w-11/12 lg:w-10/12 overflow-auto"
              style={{ backgroundColor: 'rgb(25, 27, 40)' }}
            >
              <div className="m-auto w-full md:w-5/12 lg:w-1/2">
                {step === 1 && (
                  <form
                    action="#"
                    className="flex flex-col content-center text-center p-1/10"
                  >
                    <img className="mt-7 mx-auto" src={logo} alt="logo" />
                    <div className="my-5">
                      <h2 className="text-2xl">Reset Your Password</h2>
                    </div>
                    <div className="form-group">
                      <input
                        name="email"
                        type="email"
                        placeholder="Type your email here..."
                        className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        onClick={handleEmailSubmit}
                        className="text-white rounded-sm py-3 my-3 w-11/12"
                        type="submit"
                        style={{ backgroundColor: 'rgb(47, 193, 157)' }}
                      >
                        Send OTP
                      </button>
                      <button
                        onClick={() => {
                          navigate('/login');
                        }}
                        className="text-white rounded-sm py-3 mb-6 mt-2 w-11/12"
                        type="button"
                        style={{ backgroundColor: '#dc2626' }}
                      >
                        Back
                      </button>
                    </div>
                  </form>
                )}

                {step === 2 && emailSent && (
                  <form
                    action="#"
                    className="flex flex-col content-center text-center p-1/10"
                  >
                    <img className="mt-7 mx-auto" src={logo} alt="logo" />
                    <div className="my-5">
                      <h2 className="text-2xl">Verify OTP</h2>
                    </div>
                    <div className="form-group">
                      <input
                        name="otp"
                        type="text"
                        placeholder="Enter OTP here..."
                        className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <input
                        name="newPassword"
                        type="password"
                        placeholder="Enter new password..."
                        className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <input
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm new password..."
                        className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        onClick={handleOtpSubmit}
                        className="text-white rounded-sm py-3 my-3 w-11/12"
                        type="submit"
                        style={{ backgroundColor: 'rgb(47, 193, 157)' }}
                      >
                        Reset Password
                      </button>
                      <button
                        onClick={() => {
                          setStep(1);
                          setEmail('');
                          setOtp('');
                          setNewPassword('');
                          setConfirmPassword('');
                          setEmailSent(false);
                        }}
                        className="text-white rounded-sm py-3 mb-6 mt-2 w-11/12"
                        type="button"
                        style={{ backgroundColor: '#dc2626' }}
                      >
                        Back
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </SigninForm>
    </>
  );
}

export default ForgotPassword;
