import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import meetingsService from './services/meeting.services';

function Protected(props) {
  let Component = props.component;
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!localStorage.getItem('accessToken')) {
        navigate('/login');
      } else {
        setIsAuthenticated(true);
      }
    };

    checkAuthentication();
  }, []);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div>
      <Component />
    </div>
  );
}

export default Protected;
