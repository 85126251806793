import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../components/layout/Layout';
import meetingsService from '../services/meeting.services';
import Container from '../components/container/Container';
import './Schedule.css';
import authService from '../services/auth.services';
import Multiselect from 'multiselect-react-dropdown';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Shedule() {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    time: '',
    password: '',
    hasWaitingRoom: false,
    muteParticipantsUponEntry: false,
    allowGuests: false,
    allowSystemUsers: false,
    invitees: [],
    moderators: [],
  });

  const [defaultValues, setDefaultValues] = useState({});
  const [userEmails, setUserEmails] = useState([]);
  const [errors, setErrors] = useState({});
  const [meeting, setMeeting] = useState({});

  const navigate = useNavigate();
  const { meetingId } = useParams();

  useEffect(() => {
    if (meetingId) {
      const fetchMeetingData = async () => {
        try {
          const resData = await meetingsService.getMeeting(meetingId);

          const meetingData = resData.meeting;
          const initialData = {
            title: meetingData.title || '',
            description: meetingData.description || '',
            date: meetingData.startTime.split('T')[0] || '',
            time: meetingData.startTime.split('T')[1].substr(0, 5) || '',
            password: meetingData.password || '',
            hasWaitingRoom: meetingData.hasWaitingRoom ?? false,
            muteParticipantsUponEntry:
              meetingData.muteParticipantsUponEntry ?? false,
            allowGuests: meetingData.allowGuests ?? false,
            allowSystemUsers: meetingData.allowSystemUsers ?? false,
            invitees: meetingData.invitees || [],
            moderators: meetingData.moderators || [],
          };

          setFormData(initialData);
          setDefaultValues(initialData);
          setMeeting(meetingData);
        } catch (error) {
          console.error('Error fetching meeting data:', error);
        }
      };

      fetchMeetingData();
    }

    const fetchUserEmails = async () => {
      try {
        const response = await authService.getAllEmails();
        setUserEmails(response.data);
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    };

    fetchUserEmails();
  }, []);

  const hasChanges = useMemo(
    () => JSON.stringify(defaultValues) !== JSON.stringify(formData),
    [defaultValues, formData]
  );

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateForm = () => {
    let vErrors = {};

    if (!formData.title) vErrors.title = 'Title is required';
    if (!formData.date) vErrors.date = 'Date is required';
    if (!formData.time) vErrors.time = 'Time is required';

    setErrors(vErrors);
    return Object.keys(vErrors).length === 0;
  };

  const displayError = (error) => {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        toast.error(err, {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } else {
      toast.error(error, {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    const draftedFields = ['date', 'time'];
    const payload = {};

    for (const key in formData) {
      if (
        !draftedFields.includes(key) &&
        defaultValues[key] !== formData[key]
      ) {
        payload[key] = formData[key];
      }
    }

    // Create the start date and time as an ISO 8601 string
    const initialDate = defaultValues.date
      ? new Date(`${defaultValues.date}T${defaultValues.time}`).toISOString()
      : '';

    const startDate = new Date(
      `${formData.date}T${formData.time}`
    ).toISOString();

    if (initialDate !== startDate) {
      payload.startTime = startDate;
    }

    try {
      const res = meetingId
        ? await meetingsService.updateMeeting(meeting.id, payload)
        : await meetingsService.postMeetings(payload);

      if (!res.error) {
        toast.success(
          res.message ??
            `The meeting is ${meetingId ? 'updated' : 'created'} successfully`,
          {
            style: { backgroundColor: '#212332', color: 'white' },
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          }
        );
        setTimeout(() => navigate('/meetings'), 750);
      } else {
        displayError(res.error);
      }
    } catch (err) {
      displayError(err.message || err.error);
    }
  };

  return (
    <Layout>
      <ToastContainer
        position="top-center"
        draggable={false}
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        closeButton={false}
      />
      <Container>
        <div className="schedule-form-container">
          <form onSubmit={submitHandler}>
            <h1>
              {meetingId
                ? `Update Meeting: ${meetingId}`
                : 'Create a new Meeting'}
            </h1>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                maxLength="20"
              />
              {errors.title && (
                <div className="font-bold error-message">{errors.title}</div>
              )}
            </div>
            <div className="form-group">
              <h5 className="font-bold">When does the meeting take place?</h5>
              <div className="flex space-x-4">
                <label className="relative w-1/2">
                  <input
                    name="date"
                    type="date"
                    onChange={handleChange}
                    value={formData.date}
                    className="form-input px-2 w-full pl-3"
                  />
                  {errors.date && (
                    <div className="error-message">{errors.date}</div>
                  )}
                </label>

                <label className="relative w-1/2">
                  <input
                    name="time"
                    type="time"
                    onChange={handleChange}
                    value={formData.time}
                    className="form-input px-2 w-full pl-3"
                  />
                  {errors.time && (
                    <div className="error-message">{errors.time}</div>
                  )}
                </label>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                maxLength="100"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="text"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                maxLength="20"
              />
            </div>

            <div className="form-group flex flex-wrap gap-x-8">
              <label className="relative">
                <input
                  className="mr-3"
                  type="checkbox"
                  name="hasWaitingRoom"
                  checked={formData.hasWaitingRoom}
                  onChange={handleChange}
                />
                Has Waiting Room
              </label>
              <label className="relative">
                <input
                  className="mr-3"
                  type="checkbox"
                  name="muteParticipantsUponEntry"
                  checked={formData.muteParticipantsUponEntry}
                  onChange={handleChange}
                />
                Mute Participants Upon Entry
              </label>
              <label className="relative">
                <input
                  className="mr-3"
                  type="checkbox"
                  name="allowGuests"
                  checked={formData.allowGuests}
                  onChange={handleChange}
                />
                Allow Guests
              </label>
              <label className="relative">
                <input
                  className="mr-3"
                  type="checkbox"
                  name="allowSystemUsers"
                  checked={formData.allowSystemUsers}
                  onChange={handleChange}
                />
                Allow System Users
              </label>
            </div>

            <div className="form-group">
              <label>Invitees</label>
              <Multiselect
                closeOnSelect={true}
                isObject={false}
                onSelect={(selectedList) =>
                  setFormData({ ...formData, invitees: selectedList })
                }
                onRemove={(selectedList) =>
                  setFormData({ ...formData, invitees: selectedList })
                }
                options={userEmails}
                selectedValues={formData.invitees}
                placeholder="Select one or more Invitees"
                emptyRecordMsg="No options available"
              />
            </div>
            <div className="form-group">
              <label>Moderators</label>
              <Multiselect
                closeOnSelect={true}
                isObject={false}
                onSelect={(selectedList) =>
                  setFormData({ ...formData, moderators: selectedList })
                }
                onRemove={(selectedList) =>
                  setFormData({ ...formData, moderators: selectedList })
                }
                options={userEmails}
                selectedValues={formData.moderators}
                placeholder="Select one or more Moderators"
                emptyRecordMsg="No options available"
              />
            </div>
            <button
              type="submit"
              className="submit-button mt-4"
              disabled={!hasChanges}
            >
              {meetingId ? 'Update' : 'Create'} Meeting
            </button>
          </form>
        </div>
      </Container>
    </Layout>
  );
}

export default Shedule;
