import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './vela_ft.svg';
import AuthService from '../services/auth.services';
import './Signup.css';
import SigninForm from './SigninForm';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';

function Signup() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      navigate('/');
      window.location.reload();
    }
  }, []);

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [phoneNumber, setPhoneNumber] = useState('');

  const [otp, setOtp] = useState('');
  const [step, setStep] = useState('signup'); // "signup" or "verify"
  const [errors, setErrors] = useState({});

  const validate = () => {
    const vErrors = {};

    if (!form.firstName) vErrors.firstName = 'Required field';
    if (!form.lastName) vErrors.lastName = 'Required field';
    if (!form.password) vErrors.password = 'Required field';

    if (!form.email) vErrors.email = 'Required field';
    else if (!/\S+@\S+\.\S+/.test(form.email)) vErrors.email = 'Invalid email';

    if (!phoneNumber) vErrors.phoneNumber = 'Required field';
    else if (!isValidPhoneNumber(phoneNumber))
      vErrors.phoneNumber = 'Invalid number';

    setErrors(vErrors);
    return Object.keys(vErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    try {
      const res = await AuthService.signup({
        ...form,
        phoneNumber: formatPhoneNumberIntl(phoneNumber),
      });

      if (!res.error) {
        toast.success('OTP has been sent to your email', {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
        setStep('verify');
      } else {
        displayError(res.error);
      }
    } catch (error) {
      displayError(error.message);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const res = await AuthService.verifyOTP(form.email, otp);
      if (res.accessToken) {
        localStorage.setItem('accessToken', res.accessToken);
        toast.success('The account is verified successfully', {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate('/');
        }, 1500);
      }
    } catch (err) {
      displayError(err.message);
    }
  };

  const handleResendOtp = async () => {
    try {
      await AuthService.resendOTP(form.email);
      toast.success('OTP has been resent to your email', {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      displayError(error.message);
    }
  };

  const displayError = (error) => {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        toast.error(err, {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } else {
      toast.error(error, {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <SigninForm>
      <ToastContainer
        position="top-center"
        draggable={false}
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        closeButton={false}
      />
      {step === 'signup' ? (
        <form
          onSubmit={handleRegister}
          action="#"
          className="flex flex-col content-center text-center p-1/10"
        >
          <div>
            <img className="mt-7 mx-auto" src={logo} alt="logo" />
            <h2 className="text-2xl">Sign Up Now</h2>
            <div className="flex justify-between w-11/12 my-2 mx-auto">
              <div className="form-group form-group-half inline-block">
                <input
                  name="firstName"
                  type="text"
                  className="form-control px-3 py-3 rounded-sm w-full"
                  placeholder="First Name"
                  onChange={handleInputChange}
                />
                {errors.firstName && (
                  <label className="form-error-label ml-0">
                    {errors.firstName}
                  </label>
                )}
              </div>
              <div className="form-group form-group-half inline-block">
                <input
                  name="lastName"
                  type="text"
                  className="form-control px-3 py-3 rounded-sm w-full"
                  placeholder="Last Name"
                  onChange={handleInputChange}
                />
                {errors.lastName && (
                  <label className="form-error-label ml-0">
                    {errors.lastName}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group">
              <input
                name="email"
                type="text"
                className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                placeholder="Email"
                onChange={handleInputChange}
              />
              {errors.email && (
                <label className="form-error-label">{errors.email}</label>
              )}
            </div>
            <div className="flex justify-between w-11/12 my-2 mx-auto">
              <div className="form-group form-group-half inline-block">
                <input
                  name="password"
                  type="password"
                  className="form-control px-3 py-3 rounded-sm w-full"
                  placeholder="Password"
                  onChange={handleInputChange}
                />
                {errors.password && (
                  <label className="form-error-label ml-0">
                    {errors.password}
                  </label>
                )}
              </div>
              <div className="form-group form-group-half inline-block">
                <PhoneInput
                  placeholder="Phone Number"
                  className="form-control px-3 py-3 rounded-sm w-full"
                  defaultCountry="SA"
                  style={{ color: 'white' }}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                />
                {errors.phoneNumber && (
                  <label className="form-error-label ml-0">
                    {errors.phoneNumber}
                  </label>
                )}
              </div>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="text-white rounded-sm py-3 my-3 w-11/12"
                style={{ backgroundColor: 'rgb(47, 193, 157)' }}
              >
                Sign Up
              </button>
            </div>
            <p className="text-md my-7">
              You have an account?
              <Link
                to={'/login'}
                className="no-underline text-white hover:no-underline hover:text-white focus:no-underline focus:text-white"
                href="/login"
              >
                &nbsp;Login
              </Link>
            </p>
          </div>
        </form>
      ) : (
        <form
          onSubmit={handleVerifyOtp}
          action="#"
          className="flex flex-col content-center text-center p-1/10"
        >
          <div>
            <img className="w-1/3 h-1/3 mx-auto" src={logo} alt="logo" />
            <h2 className="text-2xl">Verify OTP</h2>
            <div className="form-group">
              <input
                name="otp"
                type="text"
                className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="text-white rounded-sm py-3 my-3 w-11/12"
                style={{ backgroundColor: 'rgb(47, 193, 157)' }}
              >
                Verify OTP
              </button>
            </div>
            <p className="text-md my-7">
              Didn't receive the OTP?
              <button
                type="button"
                className="no-underline text-white hover:no-underline hover:text-white focus:no-underline focus:text-white"
                onClick={handleResendOtp}
              >
                &nbsp;Resend OTP
              </button>
            </p>
          </div>
        </form>
      )}
    </SigninForm>
  );
}

export default Signup;
