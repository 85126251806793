import { createContext, useEffect, useState } from 'react';
import userService from '../services/user.services';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  useEffect(() => {
    if (!auth.accessToken) {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        userService
          .getUserInfo()
          .then((res) => {
            if (!res.error) {
              setAuth({ user: res.data, accessToken: accessToken });
            }
          })
          .catch((err) => {
            console.log('AuthProvider error setting context', err);
          });
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
