import axios from '../api/axios';
import authHeader from './auth-header';

const MEDIA_URL = '/api/user/media';

const uploadAvatar = (file) => {
  const formData = new FormData();
  formData.append('file', file, file.name); // Include the filename
  formData.append('mimetype', file.type); // Include the mimetype
  return axios.put(`${MEDIA_URL}/upload-avatar`, formData, {
    headers: {
      ...authHeader(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getAvatar = () => {
  return axios.get(`${MEDIA_URL}/latest-avatar`, { headers: authHeader() });
};

const mediaService = {
  getAvatar,
  uploadAvatar,
};

export default mediaService;
