import Sidebar from '../home/Sidebar';
import Home from '../../pages/Home';
import PreviousMap from 'postcss/lib/previous-map';
import { Link } from 'react-router-dom';
import logo from '../../pages/velalogo.svg';
import Logout from '../../pages/Logout';
import '../../pages/bg.css';
import { useState, useEffect } from 'react';

function Layout(props) {
  const [value, setValue] = useState('1');
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    let img = new Image();
    const date = new Date();
    const num = date.getDate();
    setImgSrc(`${process.env.PUBLIC_URL}/background${num}blur.jpg`);
    img.src = `${process.env.PUBLIC_URL}/background${num}.jpg`;
    img.onload = () => {
      setImgSrc(img.src);
    };
  }, []);

  return (
    <>
      <div
        className="background1"
        style={{ backgroundImage: `url("${imgSrc}")` }}
      >
        <div className="homeeBackgroundShadow flex flex-row h-screen">
          <div
            // style={{ opacity: 0.75 }}
            className="rounded-2xl mx-0 ml-4 md:mx-3 my-4"
          >
            <div
              className="pro-sidebar rounded-2xl md collapsed"
              style={{ backgroundColor: 'rgba(25, 27, 40, 0.75)' }}
            >
              <div className="pro-sidebar-inner">
                <div className="pro-sidebar-layout">
                  <div className="pro-sidebar-header p-3">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="pro-sidebar-content flex-grow">
                    <nav className="pro-menu inner-submenu-arrows">
                      <ul>
                        <Link to="/">
                          <li className="pro-menu-item text-sm font-semibold bg-transparent rounded-lg hover:bg-gray-600 focus:bg-gray-600 focus:text-white hover:text-white focus:outline-none focus:shadow-outline">
                            <div
                              className="pro-inner-item"
                              tabIndex="0"
                              role="button"
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                  </svg>
                                </span>
                              </span>
                              <span className="pro-item-content">Home</span>
                            </div>
                          </li>
                        </Link>
                        <Link to="/meetings">
                          <li className="pro-menu-item text-sm font-semibold bg-transparent rounded-lg hover:bg-gray-600 focus:bg-gray-600 focus:text-white hover:text-white focus:outline-none focus:shadow-outline">
                            <div
                              className="pro-inner-item"
                              role="button"
                              tabIndex="0"
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                  </svg>
                                </span>
                              </span>
                              <span className="pro-item-content">Meetings</span>
                              <span className="pro-arrow-wrapper">
                                <span className="pro-arrow"></span>
                              </span>
                            </div>
                            <div
                              className="react-slidedown pro-inner-list-item closed"
                              style={{ height: 0 }}
                            >
                              <div></div>
                            </div>
                          </li>
                        </Link>
                        <Link to="/profile">
                          <li className="pro-menu-item text-sm font-semibold bg-transparent rounded-lg hover:bg-gray-600 focus:bg-gray-600 focus:text-white hover:text-white focus:outline-none focus:shadow-outline">
                            <div
                              className="pro-inner-item"
                              tabIndex="0"
                              role="button"
                            >
                              <span className="pro-icon-wrapper">
                                <span className="pro-icon">
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                  </svg>
                                </span>
                              </span>
                              <span className="pro-item-content">
                                Profile<a href="/profile"></a>
                              </span>
                            </div>
                          </li>
                        </Link>
                      </ul>
                    </nav>
                  </div>
                  <div className="pro-sidebar-footer">
                    <nav className="pro-menu inner-submenu-arrows">
                      <Logout />
                    </nav>
                  </div>
                </div>
              </div>
              <div
                className="overlay"
                role="button"
                tabIndex="0"
                aria-label="overlay"
              ></div>
            </div>
          </div>
          {props.children}
        </div>
      </div>
    </>
  );
}

export default Layout;
