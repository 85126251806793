import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Signin.css';
import logo from './vela_ft.svg';
import AuthService from '../services/auth.services';
import AuthContext from '../context/AuthProvider';
import SigninForm from './SigninForm';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState('login'); // "login" or "verify"
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({});
  const { setAuth } = useContext(AuthContext);

  const navigate = useNavigate();

  const signInUser = async (email, password) => {
    try {
      const res = await AuthService.login(email.toLowerCase(), password);
      if (!res.error) {
        setAuth(res);
        navigate('/');
      } else {
        displayError(res.error);
      }
    } catch (err) {
      if (err.message === 'Email not verified') {
        handleResendOtp();
        setStep('verify');
      } else {
        displayError(err.message || err.error);
      }
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const res = await AuthService.verifyOTP(email, otp);
      if (res.accessToken) {
        toast.success('The account is verified successfully', {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate('/');
        }, 1500);
      }
    } catch (err) {
      displayError(err.message);
    }
  };

  const handleResendOtp = async () => {
    try {
      await AuthService.resendOTP(email);
      toast.success('OTP has been sent to your email', {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      displayError(error.message);
    }
  };

  const validate = () => {
    let vErrors = {};

    if (!email) {
      vErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      vErrors.email = 'Enter a valid email';
    }

    if (!password) {
      vErrors.password = 'Password is required';
    }

    setErrors(vErrors);
    return Object.keys(vErrors).length === 0;
  };

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      signInUser(email, password);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (validate()) {
      signInUser(email, password);
    }
  };

  const displayError = (error) => {
    if (Array.isArray(error)) {
      error.forEach((err) => {
        toast.error(err, {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } else {
      toast.error(error, {
        style: { backgroundColor: '#212332', color: 'white' },
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <SigninForm>
      <ToastContainer
        position="top-center"
        draggable={false}
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        closeButton={false}
      />

      {step === 'login' ? (
        <form
          action="#"
          className="flex flex-col content-center text-center p-1/10"
        >
          <img className="mt-7 mx-auto" src={logo} alt="logo" />
          <div>
            <h2 className="text-2xl">Sign In Now</h2>
          </div>
          <div className="form-group">
            <input
              name="phoneEmail"
              type="text"
              placeholder="Email"
              className="form-control w-11/12 my-2 px-3 py-3 rounded-md"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            {errors.email && (
              <label className="form-error-label">{errors.email}</label>
            )}
          </div>
          <div>
            <div>
              <input
                name="password"
                type="password"
                placeholder="Password"
                className="form-control w-11/12 my-2 px-3 py-3 rounded-md"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {errors.password && (
                <label className="form-error-label">{errors.password}</label>
              )}
            </div>
          </div>
          <div>
            <button
              onClick={handleLogin}
              className="text-white rounded-sm py-3 my-3 w-11/12"
              type="submit"
              style={{ backgroundColor: 'rgb(47, 193, 157)' }}
            >
              LOGIN TO YOUR ACCOUNT
            </button>
          </div>
          <p className="text-md mt-7 my-1">
            Don't have an account?
            <Link
              to={'/register'}
              className="no-underline text-white hover:no-underline hover:text-white focus:no-underline focus:text-white"
            >
              &nbsp; Register
            </Link>
          </p>
          <Link to={'/forgot-password'}>
            <p className="text-md">
              Forgot Password?
              <button
                className="no-underline
            text-white 
            hover:no-underline
              hover:text-white
              focus:no-underline
                focus:text-white"
              >
                &nbsp; Here
              </button>
            </p>
          </Link>
        </form>
      ) : (
        <form
          onSubmit={handleVerifyOtp}
          action="#"
          className="flex flex-col content-center text-center p-1/10"
        >
          <div>
            <img className="w-1/3 h-1/3 mx-auto" src={logo} alt="logo" />
            <h2 className="text-2xl">Verify OTP</h2>
            <div className="form-group">
              <input
                name="otp"
                type="text"
                className="form-control w-11/12 my-2 px-3 py-3 rounded-sm"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="text-white rounded-sm py-3 my-3 w-11/12"
                style={{ backgroundColor: 'rgb(47, 193, 157)' }}
              >
                Verify OTP
              </button>
            </div>
            <p className="text-md my-7">
              Didn't receive the OTP?
              <button
                type="button"
                className="no-underline text-white hover:no-underline hover:text-white focus:no-underline focus:text-white"
                onClick={handleResendOtp}
              >
                &nbsp;Resend OTP
              </button>
            </p>
          </div>
        </form>
      )}
    </SigninForm>
  );
};

export default Signin;
