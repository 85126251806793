export function setTokenCookie(token) {
  const domain = process.env.REACT_APP_MAIN_DOMAIN;
  if (domain) {
    document.cookie = `userToken=${token}; domain=.${domain}; path=/; secure; samesite=strict`;
  } else {
    console.error('For cookies to work, set REACT_APP_MAIN_DOMAIN var');
  }
}

export function removeTokenCookie() {
  const domain = process.env.REACT_APP_MAIN_DOMAIN;
  if (domain) {
    document.cookie = `userToken=''; domain=.${domain}; path=/; secure; samesite=strict`;
  }
}
