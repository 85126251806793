import React from 'react';
import Home from '../../pages/Home';

function Container(props) {
  return (
    <div
      className="flex flex-col rounded-2xl mx-2 my-4 p-1 w-full md:w-full overflow-y-auto"
      style={{ backgroundColor: 'rgba(25, 27, 40, 0.75)' }}
    >
      {props.children}
    </div>
  );
}

export default Container;
