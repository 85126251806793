import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import meetingsService from '../../services/meeting.services';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { format } from 'date-fns';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
import { setTokenCookie } from '../../utils/cookies';

// Chip Component
const Chip = ({ email }) => {
  const chipStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0 10px',
    height: '24px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#ffffff',
    backgroundColor: '#007bff',
    borderRadius: '16px',
    margin: '2px',
    whiteSpace: 'nowrap',
  };

  const removeBtnStyle = {
    marginLeft: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#ffffff',
  };

  const RemoveModerator = () => {};
  return (
    <div style={chipStyle}>
      {email}
      <span style={removeBtnStyle} onClick={RemoveModerator}>
        ×
      </span>{' '}
      {/* X icon to remove */}
    </div>
  );
};

// ChipList Component
const ChipList = ({ emails }) => {
  return (
    <div>
      {emails.map((email, index) => (
        <Chip key={index} email={email} />
      ))}
    </div>
  );
};

const deletePopupStyles = {
  width: '350px',
  padding: '20px',
  maxWidth: '90vw',
  maxHeight: '90vh',
  borderRadius: '10px',
  color: '#000',
};

const JoinMeetingButton = ({ meetingId }) => {
  const onClick = async () => {
    const {
      data: { meeting, accessToken },
    } = await meetingsService.joinMeeting(meetingId);
    setTokenCookie(accessToken);
    window.open(
      `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`,
      '_blank'
    );
  };
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: '#4CAF50', // Green background
        border: 'none', // No borders
        color: 'white', // White text
        padding: '10px 20px', // Padding
        textAlign: 'center', // Centered text
        textDecoration: 'none', // No underline
        display: 'inline-block', // Inline-block display
        fontSize: '16px', // Font size
        margin: '4px 2px', // Margin
        cursor: 'pointer', // Pointer cursor on hover
        borderRadius: '5px', // Rounded corners
        transition: 'background-color 0.3s', // Smooth background color transition
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = '#45a049'; // Darker green on hover
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = '#4CAF50'; // Original green
      }}
    >
      Join Meeting
    </button>
  );
};

function Upcoming({ updateFirstMeetup }) {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedMeetups, setLoadedMeetups] = useState([]);
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');

  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUserInfo() {
      try {
        const res = await meetingsService.getUserInfo();
        setCurrentUserId(res.data.id);
        setCurrentUserEmail(res.data.email);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    }

    fetchUserInfo();
  }, []);

  useEffect(() => {
    if (currentUserEmail && currentUserId) {
      updateMeetings();
    }
  }, [currentUserEmail, currentUserId]);

  function updateMeetings() {
    setIsLoading(true);
    meetingsService
      .getAllUpcomingMeetings()
      .then((response) => {
        const meetups = [];
        const data = response.data.meetings.splice(0, 5);

        if (response.data.error) {
          console.log(response.data.error);
          localStorage.removeItem('accessToken');
          navigate('/login');
        }
        for (const key in data) {
          const meetup = {
            id: key,
            updateFlag: false,
            deleteFlag: false,
            ...data[key],
          };

          if (currentUserId == meetup.createdBy) {
            meetup.deleteFlag = true;
            meetup.updateFlag = true;
          } else if (
            Array.isArray(meetup.moderators) &&
            meetup.moderators.includes(currentUserEmail)
          ) {
            meetup.updateFlag = true;
          }

          meetup.googleCalendarLink = generateGoogleCalendarLink(meetup);
          meetup.outlookCalendarLink = generateOutlookCalendarLink(meetup);

          meetups.push(meetup);
        }
        setIsLoading(false);
        setLoadedMeetups(meetups);

        // Updating parent component which has 'Upcoming Meeting' section
        if (meetups.length <= 0) {
          updateFirstMeetup({
            isUpcoming: false,
          });
        } else {
          const lastMeetup = meetups[0];
          updateFirstMeetup({
            id: lastMeetup.meetingId,
            isUpcoming: true,
            upcomingTitle: format(
              new Date(lastMeetup.startTime),
              'dd MMM yyyy / p'
            ),
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Function to add default duration (1 hour) if endTime is not provided
  function getEndTime(startTime) {
    const startDate = new Date(startTime);
    const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Adds 1 hour
    return endDate.toISOString();
  }

  // Google Calendar Link Generation
  function generateGoogleCalendarLink(meeting) {
    const { title, startTime } = meeting;
    const endTime = getEndTime(startTime);
    const location = `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`;
    const description = meeting.description
      ? `&details=${encodeURIComponent(meeting.description)}`
      : '';
    const invitees = meeting.invitees?.length
      ? `&add=${meeting.invitees
          .map((email) => encodeURIComponent(email))
          .join(',')}`
      : '';

    return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      title
    )}&dates=${startTime.replace(/[-:]/g, '').slice(0, -5)}/${endTime
      .replace(/[-:]/g, '')
      .slice(0, -5)}&location=${encodeURIComponent(
      location
    )}${description}${invitees}&trp=false&sprop=&sprop=name:`;
  }

  // Outlook Calendar Link Generation
  function generateOutlookCalendarLink(meeting) {
    const { title, startTime } = meeting;
    const endTime = getEndTime(startTime);
    const location = `${process.env.REACT_APP_SUHAIL_MEET_URL}/${meeting.meetingId}`;
    const description = meeting.description
      ? `&body=${encodeURIComponent(meeting.description)}`
      : '';
    const invitees = meeting.invitees?.length
      ? `&to=${meeting.invitees
          .map((email) => encodeURIComponent(email))
          .join(';')}`
      : '';

    const formatDateTime = (dateTime) => new Date(dateTime).toISOString();

    return `https://outlook.live.com/calendar/action/compose?rru=addevent&subject=${encodeURIComponent(
      title
    )}&startdt=${formatDateTime(startTime)}&enddt=${formatDateTime(
      endTime
    )}&location=${encodeURIComponent(location)}${description}${invitees}`;
  }

  async function handleDelete(id, meetingId) {
    await meetingsService.deleteMeeting(id).then((result) => {
      updateMeetings();
      toast.success(
        'Meeting with id ' + meetingId + ' has been deleted successfully',
        {
          style: { backgroundColor: '#212332', color: 'white' },
          draggable: true,
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    });
  }

  // function deleteFailed() {
  //   toast.error("Only meeting moderators can delete this meeting", {
  //     style: { backgroundColor: "#212332", color: "white" },
  //     draggable: true,
  //     position: toast.POSITION.TOP_CENTER,
  //   });
  // }

  function copyToClipboard() {
    toast.success('Copied to clipboard!', {
      style: { backgroundColor: '#212332', color: 'white' },
      draggable: true,
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  if (isLoading) {
    return (
      <section>
        <p> Loading ...</p>
      </section>
    );
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          marginBottom: '20px',
          borderRadius: '10px',
          overflow: 'hidden',
          textAlign: 'left',
        }}
      >
        <thead
          style={{ backgroundColor: 'rgba(255,255,255,0.18', color: '#fff' }}
        >
          <tr>
            <th className="p-3">Meeting ID</th>
            <th className="p-3">Title</th>
            <th className="p-3">Start Time</th>
            <th className="p-3">Invitees</th>
            <th className="p-3">Calendar</th>
            <th className="p-3">Actions</th>
            <th className="p-3">Join Meeting</th>
          </tr>
        </thead>
        <tbody>
          {loadedMeetups.map((meeting) => (
            <tr key={meeting.id} className="border-b ">
              <td className="p-3">
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '7px' }}
                >
                  {meeting.meetingId}
                  <CopyToClipboard text={meeting.meetingId}>
                    <button
                      className="hidden sm:inline"
                      onClick={copyToClipboard}
                      title="Copy Invitation Id"
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="m-auto"
                        height="1.5em"
                        width="1.5em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="9"
                          y="9"
                          width="13"
                          height="13"
                          rx="2"
                          ry="2"
                        ></rect>
                        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                      </svg>
                    </button>
                  </CopyToClipboard>
                </div>
              </td>

              <td className="p-3" style={{ minWidth: '150px' }}>
                {meeting.title}
              </td>
              <td className="p-3">
                {format(new Date(meeting.startTime), 'dd MMM yyyy / p')}
              </td>
              <td
                className="p-3"
                title={meeting.invitees ? meeting.invitees.join(', ') : ''}
                style={{
                  maxWidth: '150px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {meeting.invitees ? meeting.invitees.join(', ') : ''}
              </td>
              <td className="p-3">
                <div className="flex gap-3 flex-wrap w-full md:w-auto space-x-1 cursor-pointer">
                  <a
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '6px',
                    }}
                    href={meeting.googleCalendarLink}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 40 40"
                      width="28"
                      height="28"
                    >
                      <path fill="#fff" d="M13 13h22v22H13z"></path>
                      <path
                        fill="#1e88e5"
                        d="M25.68 20.92l1.008 1.44 1.584-1.152v8.352H30V18.616h-1.44zM22.943 23.745c.625-.574 1.013-1.37 1.013-2.249 0-1.747-1.533-3.168-3.417-3.168-1.602 0-2.972 1.009-3.33 2.453l1.657.421c.165-.664.868-1.146 1.673-1.146.942 0 1.709.646 1.709 1.44 0 .794-.767 1.44-1.709 1.44h-.997v1.728h.997c1.081 0 1.993.751 1.993 1.64 0 .904-.866 1.64-1.931 1.64-.962 0-1.784-.61-1.914-1.418L17 26.802c.262 1.636 1.81 2.87 3.6 2.87 2.007 0 3.64-1.511 3.64-3.368 0-1.023-.504-1.941-1.297-2.559z"
                      ></path>
                      <path
                        fill="#fbc02d"
                        d="M34 42H14l-1-4 1-4h20l1 4z"
                      ></path>
                      <path
                        fill="#4caf50"
                        d="M38 35l4-1V14l-4-1-4 1v20z"
                      ></path>
                      <path
                        fill="#1e88e5"
                        d="M34 14l1-4-1-4H9a3 3 0 00-3 3v25l4 1 4-1V14h20z"
                      ></path>
                      <path fill="#e53935" d="M34 34v8l8-8z"></path>
                      <path
                        fill="#1565c0"
                        d="M39 6h-5v8h8V9a3 3 0 00-3-3zM9 42h5v-8H6v5a3 3 0 003 3z"
                      ></path>
                    </svg>
                    <p className="text-sm">Google</p>
                  </a>
                  <a
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '6px',
                    }}
                    href={meeting.outlookCalendarLink}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 40 40"
                      width="28"
                      height="28"
                    >
                      <path
                        fill="#1976d2"
                        d="M28 13h14.533c.81 0 1.467.657 1.467 1.467v19.066c0 .81-.657 1.467-1.467 1.467H28V13z"
                      ></path>
                      <path fill="#fff" d="M28 17.958h14V33.5H28z"></path>
                      <path fill="#1976d2" d="M27 44L4 39.5v-31L27 4z"></path>
                      <path
                        fill="#fff"
                        d="M15.25 16.5c-3.176 0-5.75 3.358-5.75 7.5s2.574 7.5 5.75 7.5S21 28.142 21 24s-2.574-7.5-5.75-7.5zm-.25 12c-1.657 0-3-2.015-3-4.5s1.343-4.5 3-4.5 3 2.015 3 4.5-1.343 4.5-3 4.5z"
                      ></path>
                      <path
                        fill="#1976d2"
                        d="M28.047 29.737h2.7v2.9h-2.7zM31.448 29.737h2.7v2.9h-2.7zM34.849 29.737h2.7v2.9h-2.7zM28.047 26.159h2.7v2.9h-2.7zM31.448 26.159h2.7v2.9h-2.7zM34.849 26.159h2.7v2.9h-2.7zM38.25 26.159h2.7v2.9h-2.7zM28.047 22.706h2.7v2.9h-2.7zM31.448 22.706h2.7v2.9h-2.7zM34.849 22.706h2.7v2.9h-2.7zM38.25 22.706h2.7v2.9h-2.7zM31.448 19.112h2.7v2.9h-2.7zM34.849 19.112h2.7v2.9h-2.7zM38.25 19.112h2.7v2.9h-2.7z"
                      ></path>
                    </svg>
                    <p className="text-sm">Outlook</p>
                  </a>
                </div>
              </td>
              <td className="p-3">
                <div className="inline-flex gap-1 space-x-2">
                  {meeting.updateFlag ? (
                    <button
                      onClick={() => {
                        navigate(`/meeting/${meeting.meetingId}/edit`);
                      }}
                      className="px-3 py-1.5 flex items-center justify-center border rounded hover:bg-blue-100 hover:text-black transition duration-200"
                    >
                      <svg
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 30 30"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="mr-1"
                      >
                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                      </svg>
                      Edit
                    </button>
                  ) : (
                    ''
                  )}
                  {meeting.deleteFlag ? (
                    <Popup
                      trigger={
                        <button className="px-3 py-1.5 flex items-center justify-center border rounded hover:bg-red-500 transition duration-200">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 30 30"
                            fill="currentColor"
                            className="mr-1"
                          >
                            <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                          </svg>
                          Delete
                        </button>
                      }
                      modal
                      contentStyle={deletePopupStyles}
                    >
                      {(close) => (
                        <div className="modal">
                          <div
                            className="header"
                            style={{ fontSize: '1.5em', marginBottom: '10px' }}
                          >
                            Confirm delete
                          </div>
                          <div className="content mb-6">
                            Are you sure you want to delete this meeting?
                          </div>
                          <div className="actions">
                            <button
                              className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                              onClick={() => {
                                handleDelete(meeting.id, meeting.meetingId);
                                close();
                              }}
                            >
                              Delete
                            </button>
                            <button
                              className="bg-gray-300 text-black px-4 py-2 rounded"
                              onClick={close}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </Popup>
                  ) : (
                    ''
                  )}
                </div>
              </td>
              <td className="p-3">
                <JoinMeetingButton meetingId={meeting.meetingId} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Upcoming;
